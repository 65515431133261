import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import TablaInventario from "./tablaInventario";
import CustomModal from "../../components/customModal";
import CrearProducto from "./productos/crearproducto";
import HistorialModal from "./historial/HistorialModal";
import TextField from "@mui/material/TextField";

const Inventario = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHistorialOpen, setModalHistorialOpen] = useState(false);
  const [productoCreado, setProductoCreado] = useState(0);
  const [productoSKU, setProductoSKU] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [filterText, setFilterText] = useState("");

  // Este useEffect se ejecutará cada vez que el valor de productoSKU cambie
  useEffect(() => {
    if (productoSKU) {
      console.log("Producto SKU actual:", productoSKU);
    }
  }, [productoSKU]);

  const handleFilterChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (value.length >= 3 || value.length === 0) {
      setFilterText(value);
    }
  };

  // Modal para crear un nuevo producto
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Abre el modal para editar un producto existente, pasando su SKU
  function handleOpenModalEdit(sku) {
    setProductoSKU(sku);
    setModalOpen(true);
  }

  // Abre el modal para ver el historial de un producto, pasando su SKU
  function handleOpenModalHistorial(sku) {
    setProductoSKU(sku);
    setModalHistorialOpen(true);
  }

  function hadnleCloseModalEdit() {
    setProductoSKU("");
    setModalOpen(false);
  }

  const handleCreateProducto = () => {
    setProductoCreado(productoCreado + 1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          item
          style={{ justifyContent: "space-between", display: "flex" }}
          xs={12}
          sm={10}
          sx={{ marginTop: "5vh" }}
        >
          <TextField
            size="small"
            id="outlined-basic"
            label="Buscar por SKU, EAN y NOMBRE"
            variant="outlined"
            style={{width: "30%"}}
            onChange={handleFilterChange} 
          />
          <Button variant="contained" onClick={handleOpenModal}>
            Crear producto
          </Button>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TablaInventario
            filterText={filterText}
            productoCreado={productoCreado}
            handleOpenModalEdit={handleOpenModalEdit}
            handleOpenModalHistorial={handleOpenModalHistorial}
          />
        </Grid>
      </Grid>
      <HistorialModal
        open={modalHistorialOpen}
        setOpen={setModalHistorialOpen}
        productoSKU={productoSKU}
      />

      <CustomModal
        open={modalOpen}
        onClose={productoSKU ? hadnleCloseModalEdit : handleCloseModal}
        title={productoSKU ? "Editar Producto" : "Crear Producto"}
        sx={{
          width: { xs: "90%", sm: "70%", md: "50%" },
          mx: "auto",
          mt: "5vh",
        }}
      >
        <CrearProducto
          handleCreateProducto={handleCreateProducto}
          productoSKU={productoSKU}
          handleCloseModal={handleCloseModal}
        />
      </CustomModal>
    </>
  );
};

export default Inventario;
