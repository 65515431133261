export const TOKEN = "Authorization";
export const USER = "User";
export const IDUSER = "idUser";
export const ROL = "rol";
export const USERNAME = "username";

export const getStorageItems = () => {
  return {
    Authorization: `Bearer ${sessionStorage.getItem(TOKEN)}`,
  };
};

export const returnRol = () => {
  return sessionStorage.getItem(ROL);
};
