import {
  Grid,
  Tooltip,
  Button,
  Snackbar,
  Alert,
  Backdrop,
  Typography,
} from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import CustomMaterialTable from "../../components/MaterialTable";
import { useEffect, useState } from "react";
import egressService from "../../services/egressService";
import * as styles from "./styles";
import { formatDateString } from "../../helpers";
import CustomModal from "../../components/customModal";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PrepararEgreso from "./prepararEgreso";
import Checkbox from "@mui/material/Checkbox";
import DespacharEgreso from "./despacharEgreso";
import autoTable from "jspdf-autotable";
import { returnRol } from "../../utils/constants";
import jsPDF from "jspdf";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterModal from "../../components/MFilters/index.js";
import EditIcon from "@mui/icons-material/Edit";
import ingressService from "../../services/ingressService.js";
import ModalListadoIngresos from "../ingress/listadoIngresos/modalListadoIngresos.js";




const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const initialAlert = {
  severity: "success",
  message: "",
};

const ListadoEgresos = () => {
  const [listadoDeEgresos, setListadoDeEgresos] = useState([]);
  const [openDetailModal, setOpenDetailMoodal] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [buscandoEgresos, setBuscandoEgresos] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [egresosAPreparar, setEgresosAPreparar] = useState([]);
  const [egresosADespachar, setEgresosADespachar] = useState([]);
  const [openDespacharModal, setOpenDespacharModal] = useState(false);
  const [loadingPreparacion, setLoadingPreparacion] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmarPreparado, setConfirmarPreparado] = useState([]);
  const [openConfirmarPreparado, setOpenConfirmarPreparado] = useState(false);
  const [bultos, setBultos] = useState(0);
  const [bultosPesos, setBultosPesos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [initialState, setInitialState] = useState("pending");
  const currentDate = new Date();
  const oneMonthBefore = new Date();
  const [ingressDetail, setIngressDetail] = useState({});
  const [isEdit, setIsEdit] = useState(false);



  oneMonthBefore.setMonth(currentDate.getMonth() - 1);

  const [filters, setFilters] = useState({
    from: formatDate(oneMonthBefore),
    to: formatDate(currentDate),
    type: "out",
    status: initialState,
  });

  const [paginacion, setPaginacion] = useState({
    page: 1,
    pageSize: 5,
    total: 0,
  });

  const statusOptions = {
    pending: "Pendiente",
    accepted: "Aceptado",
    closed: "Cerrado",
    cancelled: "Cancelado",
  };

  const navigate = useNavigate();

  const handleSetBultos = (bultos) => {
    setBultos(bultos);

    if (bultosPesos.length > bultos) {
      setBultosPesos(bultosPesos.slice(0, bultos));
    } else {
      for (let i = bultosPesos.length; i < bultos; i++) {
        bultosPesos.push(0);
      }
    }
  };

  const handleCheckboxChange = (egreso) => {
    setEgresosADespachar((prevEgresosPreparados) => {
      const index = prevEgresosPreparados.findIndex(
        (item) => item.id === egreso.id
      );

      if (index !== -1) {
        return prevEgresosPreparados.filter((item) => item.id !== egreso.id);
      } else {
        return [...prevEgresosPreparados, egreso];
      }
    });
  };
  const handleCheckboxChangeEnPreparacion = (egreso) => {
    setEgresosAPreparar((prevEgresosAPreparar) => {
      const index = prevEgresosAPreparar.findIndex(
        (item) => item.id === egreso.id
      );

      if (index !== -1) {
        return prevEgresosAPreparar.filter((item) => item.id !== egreso.id);
      } else {
        return [...prevEgresosAPreparar, egreso];
      }
    });
  };

  const getColorByStatus = (estado) => {
    switch (estado) {
      case "Pendiente de Preparacion":
        return "#FF0000"; // Rojo
      case "En Preparacion":
        return "#0000FF"; // Azul
      case "Preparado":
        return "#FFA500"; // Naranja
      case "Despachado":
        return "#008000"; // Verde
      default:
        return "#808080"; // Gris (predeterminado para otros estados)
    }
  };

  const handleConfirmarPreparado = (data) => {
    setOpenConfirmarPreparado(true);
    setConfirmarPreparado(data);
  };

  const handleDownloadEtiqueta = async () => {
    const etiquetasPromiseArray = [];

    for (let i = 1; i <= bultos; i++) {
      const promise = new Promise((resolve) => {
        const doc = new jsPDF({
          unit: "mm",
          format: [100, 120], // Tamaño del papel en milímetros
        });
        const fontSize = 10;
        // Agrega el contenido del PDF con los datos de la etiqueta
        if (confirmarPreparado.tipoEgreso === "Cliente Retira") {
          doc.setFontSize(fontSize);
          doc.setFont("helvetica", "bold");
          doc.text(`OP: ${confirmarPreparado.tipoEgreso}`, 10, 10);
          doc.text(`Número de Bulto: ${i} / ${bultos}`, 10, 20);
          doc.setFont("helvetica", "normal");
          doc.text(
            `Nombre y apellido: ${confirmarPreparado.nombre} ${confirmarPreparado.apellido}`,
            10,
            30
          );
          doc.text(`Teléfono: ${confirmarPreparado.direccion}`, 10, 40);
          doc.text(`DNI: ${confirmarPreparado.direccion}`, 10, 50);
          doc.text(`Comprobante: ${confirmarPreparado.comprobante}`, 10, 60);
          doc.text(`Observaciones: ${confirmarPreparado.observacion}`, 10, 70);
        } else if (confirmarPreparado.tipoEgreso === "Flete Propio") {
          doc.setFontSize(fontSize);
          doc.text(`OP: ${confirmarPreparado.tipoEgreso}`, 10, 10);
          doc.text(`Número de Bulto: ${i} / ${bultos}`, 10, 20);
          doc.text(
            `Nombre y apellido: ${confirmarPreparado.nombre} ${confirmarPreparado.apellido}`,
            10,
            30
          );
          doc.text(`Teléfono: ${confirmarPreparado.direccion}`, 10, 40);
          doc.text(`DNI: ${confirmarPreparado.direccion}`, 10, 50);
          doc.text(`Comprobante: ${confirmarPreparado.comprobante}`, 10, 60);
          doc.text(`Codigo postal: ${confirmarPreparado.comprobante}`, 10, 70);
          doc.text(`Calle: ${confirmarPreparado.direccion}`, 10, 80);
          doc.text(`Localidad: ${confirmarPreparado.comprobante}`, 10, 90);
          doc.text(`Provincia: ${confirmarPreparado.comprobante}`, 10, 100);
          doc.text(`Observaciones: ${confirmarPreparado.observacion}`, 10, 110);
        } else if (confirmarPreparado.tipoEgreso === "Redespacho") {
          doc.setFontSize(fontSize);
          doc.text(`OP: ${confirmarPreparado.tipoEgreso}`, 10, 10);
          doc.text(`Número de Bulto: ${i} / ${bultos}`, 10, 20);
          doc.text(
            `Quien redespacha (flete): ${confirmarPreparado.flete}`,
            10,
            30
          );
          doc.text(`Transporte: ${confirmarPreparado.transporte}`, 10, 40);
          doc.text(
            `Nombre y apellido: ${confirmarPreparado.nombre} ${confirmarPreparado.apellido}`,
            10,
            50
          );
          doc.text(`Teléfono: ${confirmarPreparado.direccion}`, 10, 60);
          doc.text(`DNI: ${confirmarPreparado.direccion}`, 10, 70);
          doc.text(`Comprobante: ${confirmarPreparado.comprobante}`, 10, 80);
          doc.text(`Codigo postal: ${confirmarPreparado.comprobante}`, 10, 90);
          doc.text(`Calle: ${confirmarPreparado.direccion}`, 10, 100);
          doc.text(`Localidad: ${confirmarPreparado.comprobante}`, 10, 110);
          doc.text(`Provincia: ${confirmarPreparado.comprobante}`, 50, 110);
          doc.text(`Observaciones: ${confirmarPreparado.observacion}`, 10, 120);
        } else if (confirmarPreparado.tipoEgreso === "Transporte Retira") {
          doc.setFontSize(fontSize);
          doc.text(`OP: ${confirmarPreparado.tipoEgreso}`, 10, 10);
          doc.text(`Número de Bulto: ${i} / ${bultos}`, 10, 20);
          doc.text(`Transporte: ${confirmarPreparado.transporte}`, 10, 30);
          doc.text(
            `Nombre y apellido: ${confirmarPreparado.nombre} ${confirmarPreparado.apellido}`,
            10,
            40
          );
          doc.text(`Teléfono: ${confirmarPreparado.direccion}`, 10, 50);
          doc.text(`DNI: ${confirmarPreparado.direccion}`, 10, 60);
          doc.text(`Comprobante: ${confirmarPreparado.comprobante}`, 10, 70);
          doc.text(`Codigo postal: ${confirmarPreparado.comprobante}`, 10, 80);
          doc.text(`Calle: ${confirmarPreparado.direccion}`, 10, 90);
          doc.text(`Localidad: ${confirmarPreparado.comprobante}`, 10, 100);
          doc.text(`Provincia: ${confirmarPreparado.comprobante}`, 50, 100);
          doc.text(`Observaciones: ${confirmarPreparado.observacion}`, 10, 110);
        } else if (confirmarPreparado.tipoEgreso === "Buspack") {
          doc.setFontSize(fontSize);
          const observacionesLines = doc.splitTextToSize(
            confirmarPreparado.observacion,
            80
          ); // Dividir el texto en líneas que quepan dentro de un ancho de 80mm

          doc.text(`OP: ${confirmarPreparado.tipoEgreso}`, 10, 10);
          doc.text(`Número de Bulto: ${i} / ${bultos}`, 10, 20);
          doc.text(`Transporte: ${confirmarPreparado.transporte}`, 10, 30);
          doc.text(
            `Nombre y apellido: ${confirmarPreparado.nombre} ${confirmarPreparado.apellido}`,
            10,
            40
          );
          doc.text(`Teléfono: ${confirmarPreparado.direccion}`, 10, 50);
          doc.text(`DNI: ${confirmarPreparado.direccion}`, 10, 60);
          doc.text(`Comprobante: ${confirmarPreparado.comprobante}`, 10, 70);
          doc.text(`Codigo postal: ${confirmarPreparado.comprobante}`, 10, 80);
          doc.text(`Calle: ${confirmarPreparado.direccion}`, 10, 90);
          doc.text(`Localidad: ${confirmarPreparado.comprobante}`, 10, 100);
          doc.text(`Provincia: ${confirmarPreparado.comprobante}`, 60, 100);
          observacionesLines.forEach((line, index) => {
            if (index === 0) {
              doc.text("Observaciones: " + line, 10, 110 + index * 5);
            } else {
              doc.text(line, 10, 110 + index * 5);
            }
            // Ajusta el incremento vertical según sea necesario para el espaciado entre líneas
          });
        }

        const fileName = `Etiqueta_Bulto_${i}.pdf`;
        doc.save(fileName);
        //  doc.autoPrint();
        //  doc.output("dataurlnewwindow");
        resolve(fileName);
      });

      etiquetasPromiseArray.push(promise);
    }

    try {
      const fileNames = await Promise.all(etiquetasPromiseArray);
      console.log(`Archivos descargados: ${fileNames.join(", ")}`);
    } catch (error) {
      console.error("Error al descargar etiquetas:", error);
      setAlertObj({
        severity: "error",
        message: "Error al descargar etiquetas.",
      });
      setOpenSnack(true);
    }
  };

  const fetchAllEgresos = async (page, pageSize) => {
    // if (lazyReload) {
    //   setEgresosAPreparar([]);
    //   setTimeout(() => {
    //     setBuscandoEgresos(true);
    //   }, 1500);
    // } else {
    //   setBuscandoEgresos(true);
    // }

    try {
      const result = await egressService.getAllEgress({
        ...filters,
        page,
        pageSize,
      });
      setListadoDeEgresos(result?.data?.results);
      console.log(listadoDeEgresos);

      setBuscandoEgresos(true);
    } catch (error) {
      setListadoDeEgresos(false);
    }
  };

  const handleOpenDetailModal = async (id, isConfirm) => {
    setOpenBackdrop(true);
    const result = await ingressService.getIngressById(id);
    setIngressDetail(result.data);
    setIsEdit(isConfirm);
    setOpenBackdrop(false);
    setOpenDetailMoodal(true);
  };

  useEffect(() => {
    fetchAllEgresos(paginacion.page, paginacion.pageSize);
  }, [filters]);

  const isOperario = returnRol() === "Operario";
  const sortedData = (data) => {
    if (isOperario) {
      const customOrder = {
        "Pendiente de Preparacion": 1,
        "En Preparacion": 2,
        Preparado: 3,
        Despachado: 4,
      };
      return data.sort((a, b) => {
        const orderA = customOrder[a.estado];
        const orderB = customOrder[b.estado];

        // Si el estado no está en el orden personalizado, colócalo al final
        const defaultOrder = Object.keys(customOrder).length + 1;

        return (orderA || defaultOrder) - (orderB || defaultOrder);
      });
    } else {
      return data.sort((a, b) => {
        const dateA = new Date(a.fechaCreacion).getTime();
        const dateB = new Date(b.fechaCreacion).getTime();
        return dateB - dateA;
      });
    }
  };
  const columns = [
    {
      title: "Fecha de Creacion",
      field: "date_created",
      minWidth: 150,
      render: (rowData) => formatDateString(rowData["date_created"]),
    },
    {
      title: "Cliente",
      field: "provider_name",
      maxWidth: 100,
    },
    {
      title: "Comprobante",
      field: "receipt_id",
      maxWidth: 100,
    },
    {
      title: "Estado",
      field: "status",
      maxWidth: 100,
      render: (rowData) => statusOptions[rowData.status]
    },
    {
      title: "Acciones",
      field: "acciones",
      render: (rowData) => (
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {rowData.status === "pending" && (
            <Tooltip title="Confirmar egreso">
              <EditIcon
                variant="outlined"
                onClick={() => handleOpenDetailModal(rowData.id, true)}
                style={{ cursor: "pointer", color: "green" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  /* esto lo tiene que hacer el chico nuevo asi hace peticion al backend par aleiminar uno pendiente d epreparacion */

  const handledeleteEgress = async (id) => {
    try {
      setLoadingDelete(true);
      const result = await egressService.deleteEgress({ id: id });

      if (result.status === 200) {
        setAlertObj({
          severity: "success",
          message: "Egreso eliminado correctamente",
        });

        fetchAllEgresos(true);
      } else {
        setAlertObj({
          severity: "error",
          message: "Error al eliminar el egreso",
        });
      }
    } catch (error) {
      setAlertObj({
        severity: "error",
        message: "Error al eliminar el egreso",
      });
    }

    setLoadingDelete(false);
    setOpenSnack(true);
  };

  const handleOpenDespachar = () => {
    setOpenDespacharModal(true);
  };

  const handleCloseDespacharModal = () => {
    setOpenDespacharModal(false);
  };

  const handleVerSeguimiento = (id = 1) => {
    navigate(`/seguimiento?id=${id}`);
  };

  const handlePasarAEnPreparacion = async () => {
    setLoadingPreparacion(true);
    try {
      const newPreparacion = {
        egresos: egresosAPreparar.map((element) => element.id),
      };
      const result = await egressService.PostInPreparation(newPreparacion);

      if (result.data.errores.length === 0) {
        handlePdfEnPreparacion(result.data);
        setAlertObj({
          severity: "success",
          message: "Egresos a preparacion con éxito",
        });
      } else {
        setAlertObj({
          severity: "error",
          message: "Error a en preparacion de egresos",
        });
      }
    } catch (error) {
      setAlertObj({
        severity: "error",
        message: "Error a en preparacion de egresos",
      });

      console.log(error);
    }
    setOpenSnack(true);
    setLoadingPreparacion(false);
    setEgresosAPreparar([]);
    fetchAllEgresos(false);
  };

  const sumarCantidades = (array) => {
    return array.reduce((total, elemento) => {
      return total + (elemento.cantidad || 0);
    }, 0);
  };

  const handlePdfEnPreparacion = (data) => {
    const doc = new jsPDF();
    // Obtener la fecha actual
    var hoy = new Date();

    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var aaaa = hoy.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    var fechaFormateada = dd + "/" + mm + "/" + aaaa;

    doc.text(`Egresos en preparación`, 20, 15);

    doc.text(`Fecha:   ${fechaFormateada}  ||  ID:   ${data.idPickeo}`, 20, 30);

    doc.text(
      `Total de unidades a egresar: ${sumarCantidades(data.lista)}`,
      20,
      45
    );

    // Generar tabla con la información de los productos
    console.log("nombre", data.lista);
    const body = data.lista.map((producto) => [
      producto.sku,
      producto.nombre,
      producto.cantidad,
      "", // Dejamos el espacio en blanco para el cuadro
    ]);

    // Ajustar el formato de la tabla
    autoTable(doc, {
      startY: 55,
      head: [["SKU", "Nombre", "Cantidad", "Check"]],
      body,
      didDrawCell: (data) => {
        if (data.section !== "head" && data.column.index === 3) {
          const startX = data.cell.x + 2;
          const startY = data.cell.y + 2;
          const width = 4;
          const height = 4;

          doc.rect(startX, startY, width, height);
        }
      },
    });

    doc.save(`Detalle_Egresos_En_Preparacion.pdf`);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleCloseDetailModal = () => setOpenDetailMoodal(false);
  const handleCloseConfirmarPreparado = () => {
    setOpenConfirmarPreparado(false);
    handleSetBultos(0);
    setConfirmarPreparado({});
  };

  const handleEstaParaPreparar = () => {
    if (!bultos || bultos < 1) {
      return true;
    }

    for (let peso of bultosPesos) {
      if (!peso || peso < 1) {
        return true;
      }
    }

    return false;
  };

  const handleConfirm = async () => {
    // handleDownloadEtiqueta(null, 1);
    setLoading(true);
    let bultosInt = [];
    let cantBultosInt = 0;
    try {
      for (let i = 0; i < bultos; i++) {
        bultosInt.push(parseInt(bultosPesos[i]));
      }

      cantBultosInt = parseInt(bultos);
    } catch {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error al confirmar pedido preparado",
      });
      setOpenSnack(true);
      setLoading(false);
      return;
    }

    try {
      let respuesta = await egressService.PostPrepareEgress({
        egreso: confirmarPreparado.id,
        cantidadBultos: cantBultosInt,
        pesos: bultosInt,
      });
      if (respuesta?.data?.Registrado) {
        setAlertObj({
          severity: "success",
          message: respuesta?.data?.Registrado,
        });
        handleDownloadEtiqueta(null, 1);
        setOpenSnack(true);
      } else if (respuesta?.data?.Error) {
        setAlertObj({
          severity: "error",
          message: respuesta?.data.Error,
        });
        setOpenSnack(true);
      }
    } catch {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error al confirmar pedido preparado",
      });
      setOpenSnack(true);
    } finally {
      setLoading(false);
      fetchAllEgresos();
    }
    handleCloseConfirmarPreparado();
  };

  const handleOpenFilterModal = (event) => setAnchorEl(event.currentTarget);
  const handleCloseFilterModal = () => setAnchorEl(null);
  const handleFilterApply = (newFilters) => setFilters(newFilters);


  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    setFilters({...filters, status: newValue });
  };

  return (
    <>
    <Grid container component={styles.tableContainer} padding={3}>
      <Grid container item xs={6} component={styles.filtersContainer}>
        <styles.StyledAutocomplete
          options={Object.keys(statusOptions)}
          getOptionLabel={(option) => statusOptions[option]}
          renderInput={(params) => <TextField {...params} label="Estado" />}
          value={selectedStatus}
          onChange={handleStatusChange}
        />
        <Button
          style={{
            marginLeft: "15px",
            width: "50px", 
            height: "40px",
            minWidth: "50px",
          }}
          variant="contained"
          color="primary"
          onClick={handleOpenFilterModal}
        >
          <FilterAltIcon />
        </Button>
        {/* <Button
          style={{
            marginLeft: "10px",
            width: "50px", 
            height: "40px",
            minWidth: "50px",
          }}
          variant="contained"
          color="primary"
        >
          <ClearIcon />
        </Button> */}

        <FilterModal
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          onFilter={handleFilterApply}
          initialFilters={filters}
        />
      </Grid>
      <Grid container item xs={6} component={styles.filtersContainerEnd}>
        <Link to="/crear-egreso">
          <Button variant="contained" color="primary">
            Crear egreso
          </Button>
        </Link>
      </Grid>
        {egresosAPreparar.length > 0 && (
          <Grid container item xs={12} component={styles.filtersContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={
                loadingPreparacion ? () => {} : handlePasarAEnPreparacion
              }
            >
              {loadingPreparacion ? (
                <CircularProgress color="inherit" />
              ) : egresosADespachar.length > 1 ? (
                "Pick Producto"
              ) : (
                "Picks Productos"
              )}
            </Button>
          </Grid>
        )}
        {egresosADespachar.length > 0 && (
          <Grid container item xs={12} component={styles.filtersContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDespachar}
            >
              {egresosADespachar.length > 1
                ? "Despachar egresos"
                : "Despachar egreso"}
            </Button>
          </Grid>
        )}
        <Grid container item xs={12}>
          <CustomMaterialTable
            title="Listado de Egresos"
            data={listadoDeEgresos}
            columns={columns}
            style={{ width: "100%", marginTop: "20px" }}
            mensajeDataArrayVacio={
              buscandoEgresos
                ? "Buscando egresos"
                : "No hay egresos disponibles"
            }
          />
        </Grid>
        <CustomModal
          open={openDetailModal}
          onClose={handleCloseDetailModal}
          title="Detalle"
        />
        {/* <CustomModal
          open={openConfirmarPreparado}
          onClose={handleCloseConfirmarPreparado}
          title={`Confirmar egreso preparado con ID de pickeo: ${confirmarPreparado.idPickeo}`}
        >
          <Grid container sx={{ marginBottom: "2vh" }}>
            <Typography>Se generará una etiqueta por cada bulto</Typography>
            <Grid
              item
              container
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <TextField
                label="Cantidad de bultos"
                variant="outlined"
                sx={{ width: "50%", marginBottom: "20px" }}
                name={"bultos"}
                value={bultos}
                onChange={(e) => {
                  handleSetBultos(e.target.value);
                }}
              />
            </Grid>
            <Grid
              item
              container
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                overflowX: "auto",
                height: "200px",
              }}
            >
              {bultosPesos.map((peso, index) => (
                <TextField
                  key={index}
                  label={`Peso Item ${index + 1}`}
                  variant="outlined"
                  sx={{ width: "60%", marginBottom: "20px", marginTop: "20px" }}
                  name={`peso-${index}`}
                  value={bultosPesos[index]}
                  onChange={(e) => {
                    setBultosPesos((prev) => {
                      prev[index] = e.target.value;
                      return [...prev];
                    });
                  }}
                />
              ))}
            </Grid>
            <Grid
              item
              container
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseConfirmarPreparado}
                style={{ marginRight: "16px" }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                disabled={handleEstaParaPreparar()}
              >
                {loading ? "Cargando..." : "Confirmar preparado"}
              </Button>
            </Grid>
          </Grid>
        </CustomModal> */}
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={alertObj?.severity}
            sx={{ width: "100%" }}
          >
            {alertObj.message}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
          onClick={() => setOpenBackdrop(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>

      <CustomModal
        open={openDetailModal}
        onClose={handleCloseDetailModal}
        title={isEdit ? "Confirmar egreso" : "Detalle de egreso"}
      >
        {/* <Grid
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          {!isEdit && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadPDF}
            >
              <DownloadIcon /> Descargar pdf
            </Button>
          )}
        </Grid> */}
        <div
          style={{
            maxHeight: "50vh",
            overflowY: "auto",
            padding: "20px",
          }}
        >
          <ModalListadoIngresos
            ingressDetail={ingressDetail}
            handleCloseDetailModal={handleCloseDetailModal}
            isEdit={isEdit}
            fetchAllIngress={() =>
              fetchAllEgresos(paginacion.page, paginacion.pageSize)
            }
            setAlertObj={setAlertObj}
            setOpenSnack={setOpenSnack}
          />
        </div>
      </CustomModal>

      <CustomModal
        open={openDespacharModal}
        onClose={handleCloseDespacharModal}
        title="Despacho"
        sx={{
          width: { xs: "90%", sm: "70%", md: "50%" },
          mx: "auto",
          mt: "5vh",
        }}
      >
        <DespacharEgreso
          egresosADespachar={egresosADespachar}
          handleCloseDespacharModal={handleCloseDespacharModal}
          fetchAllEgresos={fetchAllEgresos}
          setEgresosADespachar={setEgresosADespachar}
        />
      </CustomModal>
    </>
  );
};

export default ListadoEgresos;
