// format from 2024-01-02T14:21:26.009232Z

import { returnRol } from "../utils/constants";

// to 02/01/2024
export function formatDateString(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function verifyRol(roles = []) {
  let rol = returnRol();
  return roles.includes(rol);
}

export function validarMail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}


export function validarDNI(dni) {
  var re = /^[0-9]{8}$/;
  return re.test(dni);
}

export function validarTelefono(telefono) {
  var re = /^[0-9]{8,15}$/;
  return re.test(telefono);
}

