import React from "react";
import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import egressService from "../../../services/egressService";

const initialAlert = {
  severity: "success",
  message: "",
};

const DespacharEgreso = ({
  egresosADespachar,
  handleCloseDespacharModal,
  fetchAllEgresos,
  setEgresosADespachar,
}) => {
  console.log("egresosADespachar ", egresosADespachar);
  const [loading, setLoading] = useState(false);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [openAlert, setOpenAlert] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const newDespacho = {
        egresos: egresosADespachar.map((element) => element.id),
      };
      const result = await egressService.PostEgress(newDespacho);
      console.log(result);
      setOpenAlert(true);
      setAlertObj({
        severity: "success",
        message: "Egresos despachado con éxito",
      });
    } catch (error) {
      setOpenAlert(true);
      setAlertObj({
        severity: "error",
        message: "Error al confirmar el despacho",
      });
      console.log(error);
    }
    setLoading(false);
    setOpenAlert(false);
    fetchAllEgresos();
    setEgresosADespachar([]);
    handleCloseDespacharModal();
  };

  return (
    <Grid container>
      <Grid item style={{ marginBottom: "20px" }}>
        <Typography>
          Esta a punto de confirmar el despacho de{" "}
          {egresosADespachar.length > 1
            ? "los siguientes pedidos"
            : "el siguiente pedido"}
        </Typography>
      </Grid>
      <Grid item container style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <Typography>
            <b>Id</b>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <b>Comprobante</b>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <b>Cliente</b>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <b>Tipo de Egreso</b>
          </Typography>
        </Grid>
      </Grid>
      {egresosADespachar.map((element) => (
        <Grid item container style={{ marginBottom: "5px" }}>
          <Grid item xs={3}>
            <Typography>{element?.id}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{element?.comprobante}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {element?.nombre} {element?.apellido}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{element?.tipoEgreso}</Typography>
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={handleCloseDespacharModal}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {loading ? "Cargando..." : "Confirmar despacho"}
        </Button>
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={7000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default DespacharEgreso;
