import { styled } from "@mui/system";

export const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  [theme.breakpoints.down("md")]: {},
}));

export const InputGridContainer = styled("div")({
  paddingBottom: '16px',
});

export const InputField = styled("div")({
  width: "80%",
});

export const ButtonsContainer = styled("div")({
  display: 'flex',
  justifyContent: 'space-evenly',
  paddingBottom: '16px',
});

export const InputContainer = styled("div")({
  padding: '8px',
});