import { styled } from "@mui/system";
import  Autocomplete  from "@mui/material/Autocomplete";

export const tableContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",

}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "230px",
  '.MuiTextField-root': {
            width: '240px',
            height: '40px',
            '& .MuiOutlinedInput-root': {
              height: '40px !important',
            },
            '& .MuiInputLabel-root': {
              lineHeight: '0.6em !important',
              whiteSpace: 'normal',
              overflow: 'visible',
              textOverflow: 'unset',
            },
          },
          '.MuiAutocomplete-root': {
            width: '230px',
          },
}));

export const filtersContainer = styled("div")(() => ({
  paddingTop: '20px',
  justifyContent: 'start'
}));

export const filtersContainerEnd = styled("div")(() => ({
  paddingTop: '20px',
  justifyContent: 'end',
}));

export const InputGridContainer = styled("div")({
  paddingBottom: '16px',
});

export const InputField = styled("div")({
  width: "80%",
});

export const ButtonsContainer = styled("div")({
  display: 'flex',
  justifyContent: 'space-evenly',
  paddingBottom: '16px',
});

