import React, { useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ListadoDeIngresos = ({
  productos,
  handleRemoveProduct,
  withActions=false,
  withDeclared=false,
}) => {

  useEffect(() => {
    console.log(productos);
    
  }, [productos]);

  return (
    <Grid container item xs={12} style={{ marginTop: "20px" }}>
      <Grid container item xs={12} style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          <Typography>
            <b>SKU</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <b>Descripcion</b>
          </Typography>
        </Grid>
        {withDeclared &&

        <Grid item xs={2}>
          <Typography>
            <b>
              Cantidad <br /> ingresada
            </b>
          </Typography>
        </Grid>
        }
        <Grid item xs={2}>
          <Typography>
            <b>
              Cantidad <br /> declarada
            </b>
          </Typography>
        </Grid>
        {withActions && (
          <Grid item xs={2}>
            <Typography>
              <b>Acciones</b>
            </Typography>
          </Grid>
        )}
      </Grid>
      {productos.map((element) => {
        return (
          <Grid
            key={element.sku_id}
            container
            item
            xs={12}
            style={{ marginBottom: "10px" }}
          >
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Typography>{element?.product_id || element?.sku_id}</Typography>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <Typography>{element?.description || element?.name}</Typography>
            </Grid>
            {withDeclared &&
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Typography>{element?.original_count}</Typography>
            </Grid>

            }
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Typography>{element?.effective_count || element?.amountEntered}</Typography>
            </Grid>
            {withActions && (
              <Grid
                item
                xs={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <DeleteIcon
                  variant="outlined"
                  color="red"
                  onClick={() => handleRemoveProduct(element.sku)}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ListadoDeIngresos;
