import React from "react";
import { useEffect, useState } from "react";
import CustomMaterialTable from "../../components/MaterialTable";
import ingressService from "../../services/ingressService";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import { Tooltip, IconButton, Grid } from "@mui/material";

const TablaInventario = ({
  productoCreado,
  handleOpenModalEdit,
  handleOpenModalHistorial,
  filterText,
}) => {
  const [data, setData] = useState([]);
  const [buscandoInventario, setBuscandoInventario] = useState(false);
  
  const inventario = async () => {
    console.log("Método inventario se está ejecutando");
    try {
      setBuscandoInventario(true);
      let result = await ingressService.getInventory(filterText);
      if (result?.data) {
        setData(result?.data.results);
      } else {
        setData([]);
      }
      setBuscandoInventario(false);
    } catch (error) {
      console.error("Hubo un error:", error);
      setBuscandoInventario(false);
    }
  };
  
  //agregar ean
  const columns = [
    {
      title: "SKU",
      field: "sku_id",
       sorting: false,
      },
      {
        title: "EAN",
        field: "ean_id",
        sorting: false,
      },
      {
        title: "Nombre",
        field: "name",
        sorting: false,
        width: 250,
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            Pendiente <br /> Ingreso
          </div>
        ),
        field: "stock_in",
        sorting: false,
      },
      {
        title: "Stock",
        field: "stock",
        sorting: false,
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            Pendiente <br /> Egreso
          </div>
        ),
        field: "stock_out",
        sorting: false,
      },
      {
        title: "Acciones",
        sorting: false,
        render: (rowData) => (
        <Grid container>
          <Tooltip title="Editar">
            <IconButton
              aria-label="editar"
              onClick={() => {
                handleOpenModalEdit(rowData.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Historial">
            <IconButton
              aria-label="historial"
              onClick={() => {
                handleOpenModalHistorial(rowData.sku);
              }}
              >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
    // {
      //   title: 'Fecha desde',
      //   field: 'date_from',
      //   minWidth: 170,
      //   render: useCallback(
        //     (rowData) => formatDateTimeStamp(rowData.date_from),
        //     []
        //   ),
        // },
      ];
      useEffect(() => {
        inventario();
        console.log(filterText);
        
      }, [productoCreado, filterText]);
      return (
    <CustomMaterialTable
      title="Inventario"
      data={buscandoInventario ? [] : data}
      columns={columns}
      style={{ width: "100%", marginTop: "20px" }}
      mensajeDataArrayVacio={
        buscandoInventario
          ? "Buscando inventario"
          : "No hay inventario disponible"
      }
    />
  );
};

export default TablaInventario;
