import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Popover, Box, Button, TextField } from "@mui/material";

const FilterModal = ({ anchorEl, onClose, onFilter, initialFilters }) => {
  const open = Boolean(anchorEl);
  const [fromDate, setFromDate] = React.useState(initialFilters?.from || null);
  const [toDate, setToDate] = React.useState(initialFilters?.to || null);

  const formatDate = (date) => {
    if (!date) return null;
    const validDate = date instanceof Date ? date : new Date(date);
    const year = validDate.getFullYear();
    const month = String(validDate.getMonth() + 1).padStart(2, "0");
    const day = String(validDate.getDate()).padStart(2, "0");
    const hours = String(validDate.getHours()).padStart(2, "0");
    const minutes = String(validDate.getMinutes()).padStart(2, "0");
    const seconds = String(validDate.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleApply = () => {
    onFilter({
      ...initialFilters,
      from: formatDate(fromDate),
      to: formatDate(toDate),
    });
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            marginTop: "10px",
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            width: 300,
          }}
        >
          <DatePicker
            label="Desde"
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            inputFormat="dd/MM/yyyy" // Formato de visualización
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          <DatePicker
            label="Hasta"
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            inputFormat="dd/MM/yyyy" // Formato de visualización
            renderInput={(params) => (
              <TextField {...params} fullWidth sx={{ mt: 2 }} />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleApply}
          >
            Filtrar
          </Button>
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};

export default FilterModal;
